import _uniq from 'lodash.uniq'
import moment from 'moment-timezone'
import { formatCurrency, formatDate, formatTime } from '@smarttransit/common'
import { isAuthorized, addAlert } from '../../../utilities/helpers'
import { D_FIND_COUNTRY_CURRENCIES } from '../../../utilities/action-types'

import {
  findPassengerTrips,
  findPassengerTripsPendingPayout,
  findTotalPassengerTrips, findTotalPassengerTripsPendingPayout
} from '../../../services/passenger-trips-service'

import { findTransportationProfiles } from '../../../services/transportation-profiles-service'
import { findTransportationOwners } from '../../../services/transportation-owners-service'

export default {
  name: 'transportation-accounts-purchases',
  props: {
    signedInUser: Object,
    forceRootViewRefresh: Function,
    selectedAccount: Object
  },
  data () {
    return {
      apiInProgress: false,
      vehiclesApiInProgress: false,
      searchId: '',
      vehicleKeywords: '',
      countryCurrencies: {},
      selectedVehicle: null,
      dateFromMenu: null,
      dateFrom: null,
      dateToMenu: null,
      dateTo: null,
      vehicles: [],
      headers: [],
      trips: [],
      totalTrips: 0,
      selectedPaidFilter: '',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 10,
        sortBy: 'dateUpdated'
      }
    }
  },
  computed: {
    computedDateFromFormatted () {
      return this.dateFrom ? formatDate(this.dateFrom) : ''
    },
    computedDateToFormatted () {
      return this.dateTo ? formatDate(this.dateTo) : ''
    },
    computedDateFromMax () {
      const fromDateAsMoment = this.dateFrom ? moment(this.dateFrom, 'Y-MM-DD') : null
      const toDateAsMoment = this.dateTo ? moment(this.dateTo, 'Y-MM-DD') : null
      if (toDateAsMoment && (!fromDateAsMoment || fromDateAsMoment.valueOf() <= toDateAsMoment.valueOf())) {
        return toDateAsMoment.subtract(1, 'day').format('Y-MM-DD')
      }
      return ''
    },
    computedDateToMin () {
      const fromDateAsMoment = this.dateFrom ? moment(this.dateFrom, 'Y-MM-DD') : null
      const toDateAsMoment = this.dateTo ? moment(this.dateTo, 'Y-MM-DD') : null
      if (fromDateAsMoment && (!toDateAsMoment || fromDateAsMoment.valueOf() <= toDateAsMoment.valueOf())) {
        return fromDateAsMoment.add(1, 'day').format('Y-MM-DD')
      }
      return ''
    },
    currentSelectedAccount () {
      return this.$props.selectedAccount
    },
    isAdmin () {
      if (this.$props.signedInUser) {
        return isAuthorized(this.$props.signedInUser, 'admin')
      }
    }
  },
  mounted () {
    if (this.$props.signedInUser) {
      this.isTransporter = this.$props.signedInUser.roles.find((o) => (o.name === 'transporter'))
    }
    this.headers = [
      {
        text: 'Ticket',
        align: 'left',
        sortable: false
      },
      {
        text: 'Paid', // include date paid / prepaid
        align: 'left',
        sortable: false
      },
      {
        text: 'Km Travelled',
        align: 'left',
        sortable: true,
        value: 'finalDistanceTravelled'
      },
      {
        text: 'From',
        align: 'left',
        sortable: true,
        value: 'originLabel'
      },
      {
        text: 'To',
        align: 'left',
        sortable: true,
        value: 'destinationLabel'
      },
      {
        text: 'Ended By', // completed, transferred, etc.
        align: 'left',
        sortable: false
      },
      {
        text: 'Date Boarded',
        align: 'left',
        sortable: true,
        value: 'dateEmbarked'
      },
      {
        text: 'Purchased',
        align: 'left',
        sortable: true,
        value: 'dateCreated'
      }
    ]
    if (!this.$props.selectedAccount) {
      this.firstApiCalled = true
      this.searchTrips()
    }
    this.selectedAccountWatchHandle = this.$watch(() => { return this.$props.selectedAccount }, (newVal, oldVal) => {
      if (!this.apiInProgress && (this.$route.params.accountId || this.$route.params.accountId === 0)) {
        this.firstApiCalled = true
        this.searchTrips()
      }
    })
  },
  watch: {
    async vehicleKeywords (val) {
      if (!this.vehiclesApiInProgress) {
        try {
          const accountId = this.currentSelectedAccount ? this.currentSelectedAccount.id : null
          this.vehiclesApiInProgress = true
          this.vehicles = await findTransportationProfiles({
            where: val ? { licensePlateNumber: { ilike: `%${val}%` } } : undefined,
            join: accountId ? { relation: 'transportationOwnerProfile', scope: { where: { transportationOwnerId: accountId } } } : undefined
          })
          this.vehiclesApiInProgress = false
        } catch (err) {
          this.vehiclesApiInProgress = false
          addAlert({
            message: `Error in vehicle search: ${err && err.error ? err.error.message : (err && err.message ? err.message : JSON.stringify(err))}`,
            type: 'error'
          })
        }
      }
    },
    selectedPaidFilter (val) {
      // prevent automatic call on first component load
      if (this.firstApiCalled) {
        if (val) {
          this.searchId = ''
          this.selectedVehicle = ''
        }
        this.searchTrips()
      }
    }
  },
  methods: {
    getPayoutStatus (trip) {
      switch (trip.transportationUserTransactionLog.logStatus) {
        case 'completed':
          return 'Paid out'
        case 'error':
          return 'Error occurred in payout'
        case 'timedout':
          return 'Payout error (time out)'
        case 'pending':
          return 'Still being processed'
      }
    },
    endedByDate (trip) {
      if (trip.completed) {
        return trip.dateUpdatedLabel.substring(0, trip.dateUpdatedLabel.length - 5)
      }
      if (trip.dateTransferred) {
        return trip.dateTransferredLabel.substring(0, trip.dateTransferredLabel.length - 5)
      }
      if (trip.dateDisembarked) {
        return trip.dateDisembarkedLabel.substring(0, trip.dateDisembarkedLabel.length - 5)
      }
    },
    onFilterByVehicle () {
      if (this.firstApiCalled) {
        this.searchTrips()
      }
    },
    async initCountryCurrencies (countryIds) {
      const countryIdsToQuery = []
      countryIds.forEach((countryId) => {
        if (!this.countryCurrencies[countryId]) {
          countryIdsToQuery.push(countryId)
        }
      })
      if (countryIdsToQuery.length) {
        const countryCurrencies = await this.$store.dispatch(D_FIND_COUNTRY_CURRENCIES, { where: { countryId: { inq: countryIdsToQuery } } })
        if (countryCurrencies && countryCurrencies.length) {
          countryCurrencies.forEach((currency) => {
            this.countryCurrencies[currency.countryId] = currency.currencySymbol
          })
        }
      }
    },
    onPagination () {
      if (this.firstApiCalled) {
        this.searchTrips()
      }
    },
    goToPayout () {
      if (this.currentSelectedAccount) {
        this.$router.push({ name: 'transportation-accounts-payouts-payout', params: { payoutId: '0' }, query: { dateFrom: this.dateFrom || undefined, dateTo: this.dateTo || undefined } })
      } else {
        alert('Please select an account first')
      }
    },
    marshallTrip (o) {
      o.ticketId = 'n/a'
      if (o.passengerTransactionLogTrips && o.passengerTransactionLogTrips.length) {
        o.ticketId = o.passengerTransactionLogTrips[0].passengerTransactionLogId
        o.isQuickpay = o.passengerTransactionLogTrips[0].passengerTransactionLog.logMetadata.quickpay
        o.isUssdQuickpay = o.passengerTransactionLogTrips[0].passengerTransactionLog.logMetadata.quickPayType
      }
      o.paid = `${this.countryCurrencies[o.countryCurrencyId] ? this.countryCurrencies[o.countryCurrencyId] : ''}${formatCurrency(o.cost - o.balance)}`
      if (o.completed) {
        o.endedBy = 'Arrived at destination'
        o.endedByState = 'green--text'
      } else if (o.dateTransferred) {
        o.endedBy = 'Transferred'
        o.endedByState = 'default'
      } else if (o.dateDisembarked) {
        o.endedBy = 'Departed Vehicle'
        o.endedByState = 'amber--text'
      }
      o.dateCreatedLabel = `${formatTime(o.dateCreated)}, ${formatDate(o.dateCreated)}`
      o.dateUpdatedLabel = `${formatTime(o.dateUpdated)}, ${formatDate(o.dateUpdated)}`
      o.dateEmbarkedLabel = o.dateEmbarked ? `${formatTime(o.dateEmbarked)}, ${formatDate(o.dateEmbarked)}` : ''
      o.dateDisembarkedLabel = o.dateDisembarked ? `${formatTime(o.dateDisembarked)}, ${formatDate(o.dateDisembarked)}` : ''
      o.dateTransferredLabel = o.dateTransferred ? `${formatTime(o.dateTransferred)}, ${formatDate(o.dateTransferred)}` : ''
      o.dateAuditedLabel = o.dateAudited ? `${formatTime(o.dateAudited)}, ${formatDate(o.dateAudited)}` : ''
      return o
    },
    async searchTrips () {
      const { sortBy, descending, page, rowsPerPage } = this.pagination
      const offset = page === 1 ? 0 : (page * rowsPerPage) - rowsPerPage
      let params = {
        filter: {
          limit: rowsPerPage,
          offset,
          where: {},
          include: [{ passengerTransactionLogTrips: 'passengerTransactionLog' }, 'transportationUserTransactionLog']
        }
      }
      if (this.selectedPaidFilter === 'excludepaid') {
        params.filter.where.transportationUserTransactionLogId = null
      }
      const accountId = this.currentSelectedAccount ? this.currentSelectedAccount.id : null
      if (accountId || this.selectedVehicle) {
        if (this.selectedPaidFilter === 'pendingpaid') {
          params.filter.transportationOwnerId = accountId
        } else {
          params.filter.join = [{
            relation: 'transportationProfile',
            scope: {
              where: this.selectedVehicle ? { id: this.selectedVehicle.id } : undefined,
              join: accountId ? {
                relation: 'transportationOwnerProfile',
                scope: { where: { transportationOwnerId: accountId } }
              } : undefined
            }
          }]
        }
      }
      if (!sortBy || sortBy === 'dateUpdated') {
        params.filter.order = [`dateCreated ${descending ? 'DESC' : 'ASC'}`, `dateUpdated ${descending ? 'DESC' : 'ASC'}`]
      } else {
        params.filter.order = `${sortBy} ${descending ? 'DESC' : 'ASC'}`
      }
      if (this.dateFrom) {
        if (!params.filter.where.and) {
          params.filter.where.and = []
        }
        params.filter.where.and.push({ dateCreated: { gte: moment(this.dateFrom, 'Y-MM-DD').utc().toDate().toISOString() } })
      }
      if (this.dateTo) {
        if (!params.filter.where.and) {
          params.filter.where.and = []
        }
        params.filter.where.and.push({ dateCreated: { lte: moment(this.dateTo, 'Y-MM-DD').utc().add(23, 'hours').add(59, 'minutes').toDate().toISOString() } })
      }
      if (this.searchId) {
        if (!params.filter.join) {
          params.filter.join = []
        }
        params.filter.join.push({
          relation: 'passengerTransactionLogTrips',
          scope: {
            where: { passengerTransactionLogId: { ilike: `%${this.searchId}%` } }
          }
        })
      }

      this.apiInProgress = true
      try {
        let trips, totalTrips
        if (this.selectedPaidFilter === 'pendingpaid') {
          [trips, totalTrips] = await Promise.all([
            findPassengerTripsPendingPayout(params.filter),
            findTotalPassengerTripsPendingPayout({ where: params.filter.where, transportationOwnerId: params.filter.transportationOwnerId })
          ])
        } else {
          [trips, totalTrips] = await Promise.all([
            findPassengerTrips(params.filter),
            findTotalPassengerTrips({ where: params.filter.where, join: params.filter.join })
          ])
        }
        await this.initCountryCurrencies(_uniq(trips.map(o => (o.countryCurrencyId))))
        this.trips = trips.map(o => {
          return this.marshallTrip(o)
        })
        this.initTransportationOwnerNamesDisplay()
        this.totalTrips = totalTrips.count
        this.apiInProgress = false
      } catch (err) {
        this.apiInProgress = false
        addAlert({
          message: `Error in trips search: ${err && err.error ? err.error.message : (err && err.message ? err.message : JSON.stringify(err))}`,
          type: 'error'
        })
      }
    },
    async initTransportationOwnerNamesDisplay () {
      if (!this.$props.selectedAccount) {
        try {
          if (this.trips && this.trips.length) {
            this.currentCompanies = this.currentCompanies || []
            let transportationIdsToQuery = []
            this.trips.forEach((trip) => {
              let found
              for (let i = 0; i < this.currentCompanies.length; i++) {
                if ((this.currentCompanies[i].transportationOwnerProfiles || []).find((o) => (o.transportationProfileId === trip.transportationProfileId))) {
                  found = true
                  break
                }
              }
              if (!found) {
                transportationIdsToQuery.push(trip.transportationProfileId)
              }
            })
            transportationIdsToQuery = _uniq(transportationIdsToQuery)
            if (transportationIdsToQuery.length) {
              const transportationOwners = await findTransportationOwners({
                include: 'transportationOwnerProfiles',
                join: {
                  relation: 'transportationOwnerProfiles',
                  scope: { where: { transportationProfileId: { inq: transportationIdsToQuery } } }
                }
              })
              this.currentCompanies = this.currentCompanies.concat(transportationOwners)
            }
            let companyNameFound
            this.trips.forEach((trip, index) => {
              const transportationOwner = this.currentCompanies.find((o) => o.transportationOwnerProfiles.filter(f => f.transportationProfileId === trip.transportationProfileId).length)
              if (transportationOwner) {
                companyNameFound = true
                trip.transportationOwnerCompanyName = transportationOwner.companyName
                trip.fullTransportationOwnerCompanyName = trip.transportationOwnerCompanyName
                trip.transportationOwnerCompanyName = trip.transportationOwnerCompanyName.length > 10 ? trip.transportationOwnerCompanyName.substring(0, 10) + '...' : trip.transportationOwnerCompanyName
              }
            })
            if (companyNameFound) {
              this.trips = [].concat(this.trips)
            }
          }
        } catch (err) {
          addAlert({
            message: `Error in company names check: ${err && err.error ? err.error.message : (err && err.message ? err.message : JSON.stringify(err))}`,
            type: 'error'
          })
        }
      }
    }
  },
  beforeDestroy () {
    if (this.selectedAccountWatchHandle) {
      this.selectedAccountWatchHandle()
    }
  }
}
