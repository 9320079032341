<template>
  <div class="transportation-accounts-purchases">
    <form @submit.prevent="searchTrips">
      <div class="justify-end align-center layout">
        <v-btn v-if="isAdmin" @click="goToPayout" :disabled="apiInProgress">
          <v-icon>fal fa-envelope-open-dollar</v-icon> &nbsp; Payout current trip results
        </v-btn>
        <div class="mx-2 v-select--fixed-width" style="width: 200px;">
          <v-select
            :disabled="apiInProgress"
            clearable
            :items="[{ text: 'Exclude paid trips', value: 'excludepaid' }, { text: 'Trips pending payout', value: 'pendingpaid' }]"
            label="Extra filter"
            v-model="selectedPaidFilter"
          />
        </div>
      </div>
      <v-layout row wrap>
        <v-flex sm4 pa-2>
          <v-text-field
            solo
            v-model="searchId"
            append-icon="fal fa-search"
            :rules="[v => !v || v.length >= 2 || 'Min 2 characters']"
            :disabled="apiInProgress || selectedPaidFilter === 'pendingpaid'"
            type="search"
            placeholder="Search by last 7 digits or full ticket id"
            @keyup.enter="searchTrips"
            @click:append="searchTrips"
          />
        </v-flex>
        <v-flex sm3 pa-2>
          <v-autocomplete
            v-model="selectedVehicle"
            :disabled="selectedPaidFilter === 'pendingpaid'"
            :items="vehicles"
            :loading="vehiclesApiInProgress"
            :search-input.sync="vehicleKeywords"
            hide-no-data
            hide-selected
            item-text="licensePlateNumber"
            item-value="id"
            label="Vehicle"
            placeholder="Search by licence plate"
            prepend-icon="fal fa-bus"
            @change="onFilterByVehicle"
            clearable
            return-object
          />
        </v-flex>
        <v-flex sm4>
          <v-layout>
            <v-flex xs6 pa-2>
              <v-menu
                v-model="dateFromMenu"
                :close-on-content-click="false"
                full-width
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    prepend-icon="fal fa-calendar"
                    :value="computedDateFromFormatted"
                    @click:clear="dateFrom = null"
                    clearable
                    label="Boarded From"
                    readonly
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  no-title
                  :max="computedDateFromMax"
                  v-model="dateFrom"
                  @change="dateFromMenu = false"
                />
              </v-menu>
            </v-flex>
            <v-flex xs6 pa-2>
              <v-menu
                v-model="dateToMenu"
                :close-on-content-click="false"
                full-width
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    prepend-icon="fal fa-calendar"
                    :value="computedDateToFormatted"
                    @click:clear="dateTo = null"
                    clearable
                    label="To"
                    readonly
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  no-title
                  :min="computedDateToMin"
                  v-model="dateTo"
                  @change="dateToMenu = false"
                />
              </v-menu>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex sm1 pa-2>
          <v-btn small fab :disabled="apiInProgress" @click="searchTrips"><v-icon small>fal fa-filter</v-icon></v-btn>
        </v-flex>
      </v-layout>
    </form>
    <v-data-table
      :headers="headers"
      :items="trips"
      :pagination.sync="pagination"
      :total-items="totalTrips"
      :loading="apiInProgress"
      :rows-per-page-items="[10, 25, 50]"
      @update:pagination="onPagination"
      class="elevation-1"
      must-sort
    >
      <template v-slot:items="props">
        <tr>
          <td :title="props.item.ticketId">
            <v-text-field
              class="text-xs-left text-field--flat"
              :value="props.item.ticketId"
              readonly
              hide-details
            />
            <v-chip v-if="props.item.transportationOwnerCompanyName" :title="props.item.fullTransportationOwnerCompanyName" small><small>{{props.item.transportationOwnerCompanyName}}</small></v-chip>
          </td>
          <td class="text-xs-left">
            {{props.item.paid}}
            <v-chip v-if="props.item.datePrepaid && !props.item.isQuickpay" small color="primary" text-color="white"><small>prepaid</small></v-chip>
            <v-chip v-if="props.item.datePrepaid && props.item.isQuickpay" small color="info" text-color="white"><small><span v-show="props.item.isUssdQuickpay">shortcode </span>quickpay</small></v-chip>
            <v-chip v-if="props.item.transportationUserTransactionLog" :color="props.item.transportationUserTransactionLog.logStatus === 'completed' || props.item.transportationUserTransactionLog.logStatus === 'pending' ? 'success' : 'danger'" text-color="white" :title="getPayoutStatus(props.item)" small><v-icon small>fal fa-envelope-open-dollar</v-icon></v-chip>
          </td>
          <td class="text-xs-left" v-html="props.item.finalDistanceTravelled" />
          <td class="text-xs-left">
            <small>{{props.item.originLabel}}</small>
          </td>
          <td class="text-xs-left">
            <small>{{props.item.destinationLabel}}</small>
          </td>
          <td class="text-xs-left">
            <small :class="props.item.endedByState">{{props.item.endedBy}}</small>
            <v-chip v-if="props.item.endedBy" small><small>{{endedByDate(props.item)}}</small></v-chip>
          </td>
          <td class="text-xs-left" v-html="props.item.dateEmbarkedLabel" />
          <td class="text-xs-left">
            {{props.item.dateCreatedLabel}}
            <div v-if="props.item.dateAuditedLabel"><small>Audited on</small></div>
            <v-chip v-if="props.item.dateAuditedLabel" small><small>{{props.item.dateAuditedLabel}}</small></v-chip>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import src from './src'
export default src
</script>
