import { apiRequests } from '../utilities/axios-factory'
import store from '../store'

function getPassengerTrip ({ id, filter }) {
  return apiRequests.get('/v1/passenger-trip/' + id + '/', {
    params: { filter },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findPassengerTrips (filter) {
  return apiRequests.get('/v1/passenger-trip/join/', {
    params: { filter },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findTotalPassengerTrips ({ where, join }) {
  return apiRequests.get('/v1/passenger-trip/count/join/', {
    params: { where, join },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findPassengerTripsPendingPayout ({ timeoutInSeconds, where, agencyId, transportationOwnerId, order, include, limit, offset }) {
  return apiRequests.get('/v1/passenger-trip/pending-payout/', {
    params: { timeoutInSeconds, where, agencyId, transportationOwnerId, order, include, limit, offset },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findTotalPassengerTripsPendingPayout ({ timeoutInSeconds, where, agencyId, transportationOwnerId }) {
  return apiRequests.get('/v1/passenger-trip/pending-payout/count/', {
    params: { timeoutInSeconds, where, agencyId, transportationOwnerId },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

export {
  getPassengerTrip,
  findPassengerTrips,
  findTotalPassengerTrips,
  findPassengerTripsPendingPayout,
  findTotalPassengerTripsPendingPayout
}
